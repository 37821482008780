import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { Components } from "@ais3p/ui-framework";

import { IssuesContent } from "../components/issues";
import useStores from "~/core/utils/useStores";

import "./css/issues.scss";
import IssueStore from "../stores/IssueStore";
import { IssueModel } from "../models";

/**
 * Инструмент для работы с задачами СП и ЗИ
 * 
 * @param {Object} props набор параметров
 * @param {LayoutStore} params.layoutStore хранилище для работы с Layout
 * @param {LayoutItem} params.layoutItem сущность в Layout
 * @param {Boolean} params.isTracking признак отслеживания в Layout
 * @param {String} params.id id сущности, для которой октрылась вкладка в Layout. В нашем случае это uid проекта,
 *  для которого нужно отобразить список задач
 * @param {Boolean} params.hasTarget 
 * @param {String} params.tabId id вкладки в Layout
 * @param {Object} params.trackedItem  данные об отслеживаемом объекте в Layout
 * @param {Object} params.isSubVisible набор отображения боковой доп панели - тип панели:boolean
 * @param {String} params.focusUid если задан focusUid, значит происходит переход к задаче по ссылке
 */
const IssuesTool = observer((props) => {
  const {
    layoutItem,
    id,
    tabId,
    trackedItem,
    layoutStore,
    isSubVisible,
    focusUid,
    issueTracker,
    onCreateIssue
  } = props;

  const { rootStore, kindsStore } = useStores();

  const [isPending, setIsPending] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [projectUid, setProjectUid] = useState();
  
  const store = useMemo(() => {
    const store = new IssueStore({ rootStore });
    return store;
  }, []);

  useEffect(async() => {
    setIsInit(true);
    try {
      await store.init();
      if (id || focusUid) {
      // Если был передан id проекта или uid задачи focusUid
        await init(id, focusUid);
        return;
      } 
      if (!id) {
      // если не был передан id проекта, то смотрим, был ли  сохранен в конфигурации id
      // предыдущего проекта
        const lastPrUid = store.getItemConfig("lastProject");
        await init(lastPrUid);
        return;
      } 
      await init();
    } finally {
      setIsInit(false);
    }
  }, [id, focusUid]);

  const init = async(id, focusUid) => {
    setIsPending(true);
    try {
      let projectUid; 
      let kindMember;
      if (id) {
        if (!focusUid) {
          const res = await store.checkProjectsLinks(id);
          kindMember = kindsStore.getKindMemberSync(id);
          if (res) {
            // значит проект в Redmine  уже заведен
            setProjectUid(id);
            projectUid = id;
          } else if (!!id && focusUid !== "tmp") {
            // если проект с Redmine еще не заведен, то создаем новый проект
            if (kindMember && kindMember.item) {
              const uid = await store.createRedmineProject(id, kindMember.item.name); 
              // обновляем список проектов
              await store.loadLinkedProjects();
              if (!!uid) {
                projectUid = uid;
                setProjectUid(uid);
              }
            }
          }
        } else {
          const res = await store.checkProjectsLinks(id);
          if (res) {
            // значит проект в Redmine  уже заведен
            setProjectUid(id);
            projectUid = id;
          }
          // setProjectUid(id);
        }
      }
      // загружаем необхоимые словари данных - трекеры, статусы и тп
      await store.initProject(projectUid);
      
      // теперь проверяем, что все необходимые трекеры связаны с проектом
      if (projectUid && kindMember) {
        const allowedKindUids = store.trackerList
          .filter((tracker) => {
            const kind = kindsStore.getKind(tracker.uid);
            return kindMember && kindMember.allowedTasks.has(kind?.name);
          }).map((tracker) => {
            return tracker.uid;
          });
        await store.checkProjectTrackers(projectUid, allowedKindUids);
      }
    } finally {
      setIsPending(false);
    }
  };

  const targetIsIssue = !!focusUid;
  
  const permIssues = useMemo(() => {
    // const { permissions } = accountStore;
    // const allowedObjects = permissions.get("object");
    // const permIssues = allowedObjects.get("tasks.Tracker");

    // TODO: После того как в конфигурации системы данный инструмент будет прописан в permissions
    // для пользователей, то нужно будет проверять права - раскоментировать предыдущие три строчки
    const perms = new Map();
    return perms.set("write", true);
  }, []);

  const onCancelCreateIssue = useCallback(() => {
    if (focusUid === "tmp") {
      layoutItem && layoutItem.close();
    }    
  }, [id, focusUid, layoutItem]);

  const onIssueCreated = useCallback((...args) => {
    let issue;
    for (const item of args) {
      if (item instanceof IssueModel) {
        issue = item;
      }
    }

    if (issue && targetIsIssue) {
      layoutItem.setName(issue.title);
    }

    onCreateIssue && onCreateIssue(...args);
  }, [onCreateIssue, layoutItem, targetIsIssue]);

  return (  
    <div className="issues-tool">
      <div className="issues-tool-content">
        {(isPending || isInit) && <Components.Preloader size={3} className={"issues-preloader"} />}
        {!isInit &&
          <IssuesContent
            store={store}
            trackedItem={trackedItem}
            projectUid={projectUid}
            issueUid={targetIsIssue ? focusUid : undefined}
            canBeEditable={permIssues && permIssues.has("write")}
            tabId={tabId}
            layoutStore={layoutStore}
            layoutItem={layoutItem}
            isSubVisible={isSubVisible}
            onCreateIssue={onIssueCreated}
            onCancelCreateIssue={onCancelCreateIssue}
            issueTracker={issueTracker}
          />
        }
      </div>
    </div>
  );
});

IssuesTool.propTypes = {
  layoutItem:   PropTypes.object,
  isTracking:   PropTypes.bool,
  id:           PropTypes.string,
  hasTarget:    PropTypes.bool,
  tabId:        PropTypes.string,
  trackedItem:  PropTypes.object,
  layoutStore:  PropTypes.object,
  isSubVisible: PropTypes.object,
  focusUid:     PropTypes.string
};


export default IssuesTool;
