import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";

import { Components } from "@ais3p/ui-framework";

import TableColHeader from "./TableColHeader";
import TableCell from "./TableCell";
import ColumnGroups from "./ColumnGroups";
import Pagination from "~/core/components/pagination";

import {
  ISSUE_TABLE_COLUMNS,
  ISSUE_TABLE_COLUMN_GROUPS
} from "../../constants/values";

import { FiltersPanel } from "../filters";

import { TABLE_COLUMNS_CFG } from "../../constants/config";
import IssueStore from "../../stores/IssueStore";

/**
 * Панель для отображения списка задач в табличном предтавлении
 * 
 * @param {Object} props набор параметров
 * @param {String} params.projectUid uid проекта
 * @param {String} params.className пользовательский className
 * @param {Boolean} params.canBeEditable признак, можно ли редактировать Задачу
 * @param {IssueStore} params.store хранилище для работы с Задачами
 * @param {LayoutItem} params.layoutItem сущность в Layout
 * @param {Function} params.reload ф-я для перезагрузки списка задач
 */
const IssuesTable = observer((props) => {
  const [isShownCheckColumns, setShownCheckColumns] = useState(false);
  const { className, store, projectUid, layoutItem, reload } = props;
  const tableActiveColumnsCfg = store.getItemConfig(TABLE_COLUMNS_CFG)?.active || {};

  const [activeColumns, setActiveColumns] = useState(
    Object.keys(ISSUE_TABLE_COLUMNS).reduce((arr, key) => {
      const isActive = Object.prototype.hasOwnProperty.call(
        tableActiveColumnsCfg,
        key
      )
        ? tableActiveColumnsCfg[key]
        : ISSUE_TABLE_COLUMNS[key].defaultActive;
      return { ...arr, [key]: isActive };
    }, {})
  );

  const onPageSizeChange = useCallback(
    (size) => {
      store.setPageSize(size);
      store.setCurrentPage(1);
      reload && reload();
    },
    [store.pageSize]
  );

  const onPageChange = useCallback(
    (p) => {
      store.setCurrentPage(p);
      reload && reload();
    },
    [store.currentPage]
  );

  const onCheckColumn = useCallback(
    (e) => {
      const { name } = e.target;
      setActiveColumns((columns) => {
        return {
          ...columns,
          [name]: !columns[name]
        };
      });

      const cfg = store.getItemConfig(TABLE_COLUMNS_CFG);
      store.setItemConfig(TABLE_COLUMNS_CFG, {
        ...cfg,
        active: {
          ...activeColumns,
          [name]: !activeColumns[name]
        }
      });
    },
    [activeColumns]
  );

  const onToggleShowFilters = useCallback(() => {
    store.toggleShowFilters();
    setShownCheckColumns(false);
  }, [store.isShownFilters]);

  const onToggleShowCheckColumns = useCallback(() => {
    store.hideFilters();
    setShownCheckColumns((state) => {
      return !state;
    });
  }, [isShownCheckColumns, store.isShownFilters]);

  const columns = useMemo(() => {
    return Object.keys(activeColumns)
      .filter((key) => {
        return activeColumns[key];
      });
  }, [activeColumns]);

  const getTrProps = useCallback((issue, index) => {
    if (issue.isExpired) {
      return { className: "expired" };
    }
    const nextIssue = (index < store.issueList.length - 1) && store.issueList[index + 1];
    if (nextIssue && nextIssue.isExpired) {
      return { className: "next-expired" };
    }

    return {};
  }, []);

  return (
    <div className={classNames("issues-table-wrapper", className)}>
      <div className={"issues-header"}>
        <div className={"issues-header-panels"}>
          <FiltersPanel store={store} projectUid={projectUid} />
          {isShownCheckColumns && (
            <div className="issues-table-columns-сhecks">
              {Object.keys(ISSUE_TABLE_COLUMN_GROUPS).map((key) => {
                return (
                  <ColumnGroups
                    key={key}
                    title={key}
                    groups={ISSUE_TABLE_COLUMN_GROUPS[key]}
                    activeColumns={activeColumns}
                    onCheckColumn={onCheckColumn}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className={"issues-header-buttons"}>
          <div
            className={classNames("header-button", {
              active: store.isShownFilters
            })}
            onClick={onToggleShowFilters}
          >
            <Components.Icon size={2} name="filiter-M" />
          </div>
          <div
            className={classNames("header-button", {
              active: isShownCheckColumns
            })}
            onClick={onToggleShowCheckColumns}
          >
            <Components.Icon size={2} name="table-col-plus-M" />
          </div>
        </div>
      </div>
      <div className={classNames("issues-table-content")}>
        <table>
          <thead>
            <tr>
              {columns.map((id) => {
                return (
                  <TableColHeader 
                    key={id}
                    id={id}
                    store={store}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {
              store.issueList.map((issue, i) => {
                return (
                  <tr 
                    key={issue.uid}
                    {...getTrProps(issue, i)}
                  >
                    {columns.map((id) => {
                      return (
                        <TableCell 
                          key={id}
                          columnId={id}
                          issue={issue}
                          store={store}
                          layoutItem={layoutItem}
                        />
                      );
                    })}
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
      <Pagination
        className="issues-pagination"
        startPageIndex={1}
        canPrevious={store.canPreviousPage}
        canNext={store.canNextPage}
        pageSize={store.pageSize}
        page={store.currentPage}
        pages={store.pages}
        reload={reload}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        loading={store.isProcessing}
      />
    </div>
  );
});

IssuesTable.propTypes = {
  className:     PropTypes.string, 
  store:         PropTypes.instanceOf(IssueStore).isRequired, 
  canBeEditable: PropTypes.bool, 
  projectUid:    PropTypes.string.isRequired, 
  layoutItem:    PropTypes.object,
  reload:        PropTypes.func

};


export default IssuesTable;
