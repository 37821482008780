import React, { useState, useCallback, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Field, Components } from "@ais3p/ui-framework";
import useStores from "~/core/utils/useStores";
import { FILTERS_CFG } from "../../constants/config";
import generateFiltersData from "./generateFiltersData";
import FilterInfo from "./FilterInfo";
import Status from "./StatusSelect";
import IssueStore from "../../stores/IssueStore";

/**
 * Панель с фильтрами для филтрации списка задач
 * 
 * @param {Object} props набор параметров
 * @param {String} params.className  пользовательский className
 * @param {IssueStore} params.store хранилизе для работы с задачами
 * @param {String} params.projectUid uid связанного проекта
 */
const FiltersPanel = observer(({ className, store, projectUid }) => {
  const { userStore } = useStores();

  const [isPending, setIsPending] = useState(false);

  const [values, setValues] = useState(
    (store.getItemConfig(FILTERS_CFG)?.projects || {})[projectUid] || {}
  );

  const project = useMemo(() => {
    return store.getProjectByUid(projectUid);
  }, [projectUid]);

  const tarckersOptions = useMemo(() => {
    if (!project) {
      return [];
    }
    return project.trackers.map((tr) => {
      return { label: tr.title, value: tr.uid || tr.id };
    });
    // return store.trackerList.map((tr) => {
    //   return { label: tr.title, value: tr.uid };
    // });
  }, [project]);

  useEffect(() => {
    setValues((store.getItemConfig(FILTERS_CFG)?.projects || {})[projectUid] || {});
  }, [projectUid]);

  const onChangeField = useCallback((value, name) => {
    setValues((state) => {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        delete state[name];
        return {
          ...state
        };
      } else {
        return {
          ...state,
          [name]: value
        };
      }
    });
  }, []);

  const onSetFilters = useCallback(async() => {
    loadData(values);
    store.clearSelectedIssues();
  }, [values]);

  const onResetFilters = useCallback(async() => {
    setValues({});
    loadData({});
    store.clearSelectedIssues();
  }, [values]);

  const loadData = async(filters) => {
    setIsPending(true);
    try {
      const data = generateFiltersData(filters, projectUid, store);
      await store.loadIssuesByFilter(data);
      const cfg = store.getItemConfig(FILTERS_CFG) || {};
      const projects = cfg.projects || {};
      projects[projectUid] = filters;
      store.setItemConfig(FILTERS_CFG, {
        ...cfg,
        projects
      });
    } catch (e) {
      store.onError(e);
    } finally {
      setIsPending(false);
    }
  };

  const userOptions = useMemo(() => {
    const project = store.getProjectByUid(projectUid);
    if (project?.isExternal) {
      return store.userList.map((user) => {
        return { label: user.shortName, value: user.id };
      });
    }

    return userStore.list.map((user) => {
      return { label: user.shortName, value: user.uid };
    });
  }, [projectUid, store, userStore?.list?.length, store?.userList?.length]);

  return (
    <div className={classNames("filters", className)}>
      {!store.isShownFilters && Array.from(Object.keys(values)).length > 0 && (
        <FilterInfo store={store} projectUid={projectUid} />
      )}
      {store.isShownFilters && (
        <div className={classNames("filters-wrapper")}>
          <div id="filterDate" />
          <div className={"filters-content"}>
            <div className={"filters-content-parameters"}>
              <div className={"filters-content-parameters-fields"}>
                <Field.String
                  label="Заголовок задачи"
                  placeholder="Укажите текст заголовка задачи"
                  icon={"search-M"}
                  className="text-area"
                  labelOnTop={true}
                  name="subject"
                  value={values.subject}
                  onChange={onChangeField}
                />
                <Field.MultiSelect
                  label="Трекер"
                  placeholder="Выберите трекер(ы)"
                  icon={"data-enum-single-M"}
                  labelOnTop={true}
                  name="tracker"
                  value={values.tracker}
                  onChange={onChangeField}
                  multiselect={true}
                  isClearable={true}
                  isRequired={false}
                  readOnly={false}
                  options={tarckersOptions}
                />
                <Field.MultiSelect
                  label="Приоритет"
                  placeholder="Выберите приоритет(ы)"
                  icon="data-enum-multi-M"
                  labelOnTop={true}
                  name="priority"
                  value={values.priority}
                  onChange={onChangeField}
                  multiselect={true}
                  isClearable={true}
                  isRequired={false}
                  readOnly={false}
                  options={store.priorityList.map((pr) => {
                    return { label: pr.title, value: pr.id };
                  })}
                />
                <Status
                  name="status"
                  value={values.status}
                  store={store}
                  onChange={onChangeField}
                />
              </div>
              <div className="vertical-divider" />
              <div className={"filters-content-parameters-fields"}>
                <div className="date-block">
                  <Field.DateTime
                    portalId="filterDate"
                    label="Дата создания"
                    placeholder="с"
                    name="createDateFrom"
                    className={"date-from"}
                    value={values.createDateFrom}
                    onChange={onChangeField}
                  />
                  <span className="dots"></span>
                  <Field.DateTime
                    portalId="filterDate"
                    placeholder="по"
                    name="createDateTo"
                    className={"date-to"}
                    value={values.createDateTo}
                    onChange={onChangeField}
                  />
                </div>
                <div className="date-block">
                  <Field.DateTime
                    portalId="filterDate"
                    label="Дата изменения"
                    placeholder="c"
                    name="updateDateFrom"
                    className={"date-from"}
                    value={values.updateDateFrom}
                    onChange={onChangeField}
                  />
                  <span className="dots"></span>
                  <Field.DateTime
                    portalId="filterDate"
                    placeholder="по"
                    name="updateDateTo"
                    className={"date-to"}
                    value={values.updateDateTo}
                    onChange={onChangeField}
                  />
                </div>
                <div className="date-block">
                  <Field.DateTime
                    portalId="filterDate"
                    label="Дата начала"
                    placeholder="c"
                    name="startDateFrom"
                    className={"date-from"}
                    value={values.startDateFrom}
                    onChange={onChangeField}
                  />
                  <span className="dots"></span>
                  <Field.DateTime
                    portalId="filterDate"
                    placeholder="по"
                    name="startDateTo"
                    className={"date-to"}
                    value={values.startDateTo}
                    onChange={onChangeField}
                  />
                </div>
                <div className="date-block">
                  <Field.DateTime
                    portalId="filterDate"
                    label="Дата окончания"
                    placeholder="c"
                    name="dueDateFrom"
                    className={"date-from"}
                    value={values.dueDateFrom}
                    onChange={onChangeField}
                  />
                  <span className="dots"></span>
                  <Field.DateTime
                    portalId="filterDate"
                    placeholder="по"
                    name="dueDateTo"
                    className={"date-to"}
                    value={values.dueDateTo}
                    onChange={onChangeField}
                  />
                </div>
              </div>
            </div>
            <div className="horisontal-divider" />
            <div className="filters-users-group">
              <div className={"filters-content-parameters-fields"}>
                <Field.MultiSelect
                  label="Автор"
                  placeholder="Выберите пользователя(ей)"
                  icon="app-usersgroups-M"
                  labelOnTop={true}
                  name="author"
                  value={values.author}
                  onChange={onChangeField}
                  multiselect={true}
                  isClearable={true}
                  isRequired={false}
                  readOnly={false}
                  options={userOptions}
                />
              </div>
              <div className="vertical-divider disabled" />
              <div className={"filters-content-parameters-fields"}>
                <Field.MultiSelect
                  label="Исполнитель"
                  placeholder="Выберите пользователя(ей)"
                  icon="app-usersgroups-M"
                  name="assignedTo"
                  value={values.assignedTo}
                  onChange={onChangeField}
                  multiselect={true}
                  isClearable={true}
                  isRequired={false}
                  readOnly={false}
                  options={userOptions}
                />
              </div>
            </div>
            <div className="horisontal-divider" />
            <div className={"filters-content-buttons"}>
              <div className={"filters-content-buttons-right"}>    
                <Components.Button
                  icon="clean-M"
                  text="Сбросить"
                  isLoading={isPending}
                  onPress={onResetFilters}
                />
                <Components.Button
                  icon="ok-M"
                  text="Задать"
                  color="positive"
                  isLoading={isPending}
                  onPress={onSetFilters}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

FiltersPanel.propTypes = {
  className:  PropTypes.string,
  store:      PropTypes.instanceOf(IssueStore).isRequired,
  projectUid: PropTypes.string.isRequired
};

export default FiltersPanel;
