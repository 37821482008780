import { observer } from "mobx-react";
import React, { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { ContextMenu, Modal, Components  } from "@ais3p/ui-framework";
import InlinePicture from "./InlinePicture";
import getTimePeriod from "../../../core/utils/getTimePeriod";

const Chunk = observer(({ data, setContextMenu }) => {
  const {
    uid,
    isEditable,
    isLocked,
    isLockedByMe,
    isParentLocked,
    isParentLockedByMe,
    isFirst,
    isLast,
    delta,
    store,
    renderValue,
    diffCompatitor,
    valueLength,
    diffClass,
    additionalClasses,
    isVersion,
    isInsertInlinePicture,
    parent,
    originClass,
    originTooltip,
    originDatetime
  } = data;

  const elementRef = useRef();
  const [src, setSrc] = useState(null);
  const refInput = useRef();

  useLayoutEffect(() => {
    if (elementRef && elementRef.current && isEditable) {
      elementRef.current.focus();
      if (delta < 0) {
        // set caret to last position if came from right chunk
        setCaretAtPosition(valueLength);
        data.setDelta();
      }
    }
  }, [elementRef, isEditable, delta, valueLength, data]);

  const setCaretAtPosition = useCallback(
    (position) => {
      if (elementRef && elementRef.current && isEditable) {
        elementRef.current.focus();
        const element = elementRef.current.childNodes[0] || elementRef.current;
        const validPosition = Math.min(position, element.textContent.length);
        const range = document.createRange();
        range.setStart(element, validPosition);
        range.setEnd(element, validPosition);
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(range);
      }
    },
    [elementRef, isEditable]
  );

  const saveValue = useCallback(() => {
    if (elementRef && elementRef.current) {
      const value = elementRef && elementRef.current.textContent;
      data.unlock();
      data.setValue(value, true);
    }
  }, [elementRef]);

  const changeValue = useCallback(() => {
    saveValue();
    if (store.editingChunkId === uid) {
      store.unsetEditingChunkId();
    }
    data.checkForEmptinessAndRemove();
  }, [store.editingChunkId, uid]);
  
  const keyPress = useCallback(async(e) => {
    const { key: code, shiftKey } = e;
    const selection = window.getSelection();
    const value = elementRef && elementRef.current.textContent;
    const { anchorOffset } = selection;
    const isFirstChar = anchorOffset === 0;
    const isLastChar = anchorOffset === value.length;

    const isPartOfSelection = window.getSelection().containsNode(elementRef && elementRef.current, true);
    if (code === "Backspace") {
      if (isFirstChar && !isFirst) {
        // Проверяем наличие inline перед текущим элементом и удаляем
        data.checkForSiblingInlineAndRemoveIt(code);
        e.preventDefault();
        data.setPrevChild();
      }
      if (isFirstChar && isFirst) {
        const prevIndentId = parent.parent.getPrevId(parent.uid);
        const prevIndent = data.getItemById(prevIndentId);
        const isEmptyChunk = parent.items.length === 1 && parent.items[0].isNew;
        if (isEmptyChunk) {
          parent.parent.deleteItemId(parent.uid);
          parent.delete(parent.uid);
          prevIndent.setEditing();
        } else {
          await store.mergeElements(prevIndent, parent, prevIndent.idsArray.length);
          data.setPrevChild();
        }
      } else if (isFirstChar && !isPartOfSelection) {
        e.preventDefault();
        if (isFirst) {
          data.mergeByCondition(-1);
          data.setPrevChild();
        } else {
          data.setPrevChild();
        }
      }
    } else if (code === "Delete") {
      if (isLastChar && !isLast) {
        // Проверяем наличие inline после текущего элемента и удаляем
        data.checkForSiblingInlineAndRemoveIt(code);
        e.preventDefault();
        data.setNextChild();
      }
      if (!value.length) {
        e.preventDefault();
        data.setNextChild();
        data.selfDestruct();
      } else {
        if (isLastChar && isLast) {
          const nextIndentId = parent.parent.getNextId(parent.uid);
          const nextIndent = data.getItemById(nextIndentId);
          store.mergeElements(parent, nextIndent, parent.idsArray.length);
        } else if (isLastChar && !isPartOfSelection) {
          e.preventDefault();
          if (isLast) {
            data.mergeByCondition(1);
          } else {
            data.setNextChild();
          }
        }
      }
    } else {
      if (code === "Enter") {
        e.preventDefault();
        saveValue();
        data.splitAtPosition(anchorOffset, shiftKey);
      }

      if (new Set(["ArrowLeft", "ArrowUp"]).has(code) && isFirstChar) {
        e.preventDefault();
        saveValue();
        data.setPrevChild(code);
        data.checkForEmptinessAndRemove();
      } else if (
        new Set(["ArrowDown", "ArrowRight"]).has(code) &&
          isLastChar
      ) {
        e.preventDefault();
        saveValue();
        data.setNextChild(code);
        data.checkForEmptinessAndRemove();
      }
    }
  },
  [valueLength, elementRef, isLast, isFirst, parent, store]
  );

  const setEditing = useCallback((e) => {
    e.stopPropagation();
    data.setEditing();
  }, [data]);

  const contextMenuCollect = useCallback(
    () => {
      if (isVersion || parent.isCaption) {
        setContextMenu && setContextMenu([]);
        return;
      } else {
        const menuItems = [
          {
            icon:   "image-M",
            title:  "Вставить рисунок",
            action: "setInsertingInlinePicture"
          },
          {
            icon:   "formula-M",
            title:  "Вставить формулу",
            action: "insertInlineFormula",
            data:   data.caretPosition
          }
        ];
        setContextMenu && setContextMenu(menuItems);
      }
    }, [setContextMenu, isVersion, data.caretPosition]);

  const handleContextMenu = useCallback((e) => {
    e.preventDefault();
    data.setEditing();
    const selection = window.getSelection();
    const { anchorOffset } = selection;
    data.setCaretPosition(anchorOffset);
  }, []);

  const onConfirmPicture = useCallback(async() => {
    data.insertPicture(data.caretPosition, src);
    onCancelPicture();
  }, [src]);

  const onCancelPicture = useCallback(() => {
    data.setInsertInlinePicture();
    setSrc(null);
  }, []);

  const onBrowse = useCallback(() => {
    if (refInput && refInput.current) {
      refInput.current.value = null;
      refInput.current.click();
    }
  }, [refInput]);

  const onSelectImage = useCallback(async(e) => {
    const file = e.target.files[0];
    try {
      const src = await store.uploadFile(file);
      setSrc(src);
    } catch (ex) {
      console.warn(ex.message);
    }
  }, []);
  const [startId, setStartId] = useState(null);
  const [endId, setEndId] = useState(null);
  const [startOffset, setStartOffset] = useState(null);
  const [endOffset, setEndOffset] = useState(null);

  useEffect(() => {
    const handleMouseUp = () => {
      const selection = window.getSelection();

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);

        const startNode = range.startContainer;
        const endNode = range.endContainer;

        const startSpan = startNode.nodeType === 3 ? startNode.parentElement : startNode;
        const endSpan = endNode.nodeType === 3 ? endNode.parentElement : endNode;

        const startId = startSpan.closest("span[id]")?.id || null;
        const endId = endSpan.closest("span[id]")?.id || null;

        const startOffset = range.startOffset;
        const endOffset = range.endOffset;
        setStartId(startId);
        setEndId(endId);
        setStartOffset(startOffset);
        setEndOffset(endOffset);
      }
    };

    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const handlePaste = useCallback(async(e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData;
    // Получаем текущее выделение
    const selection = window.getSelection();
    const value = elementRef && elementRef.current.textContent;
    const { anchorOffset } = selection;
    const isFirstChar = anchorOffset === 0;
    const isLastChar = anchorOffset === value.length;
    const pastedChunks = [];

    const pastedText = clipboardData.getData("text/plain"); // временное решения для фикса по T6335, будет переделано в T6266
    if (!pastedText) {
      return;
    }
    // Разделяем текст по строкам 
    const lines = pastedText.split("\n").filter((line) => {
      return line !== "";
    });
    const htmlContent = clipboardData.getData("text/html");
    if (!htmlContent) {
      console.log("No HTML content available.");
      return;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const childNodes = doc.body.childNodes;
    const processNode = async(node) => {
      if (node && node.nodeType === Node.ELEMENT_NODE) {
        if (node.className.startsWith("chunk")) {
          const text = node.innerText.trim();
          pastedChunks.push(text);
        }
      }
    };
    for (const node of childNodes) {
      await processNode(node);
    }

    pastedChunks.length > 0 && 
    parent.splitAtItemWithArrayOfString(uid, { startId, endId, startOffset, endOffset }, pastedChunks);
    pastedChunks.length < 1 && 
    data.pasteFromClipboard(lines, { startId, endId, startOffset, endOffset }, isFirstChar, isLastChar);
  }, [parent, uid, startId, endId, startOffset, endOffset]);

  const datestring = useMemo(() => {
    return getTimePeriod(originDatetime);
  }, [new Date(), originDatetime]);

  const modalButtons = useMemo(() => {
    return [
      ( 
        <Components.Button
          key="add"
          text="Добавить"
          icon="plus-M"
          onPress={onConfirmPicture}
          isDisabled={!src}
          color="action"
        />
      ), (
        <Components.Button
          key="cancel"
          text="Отмена"
          icon="cancel-M"
          onPress={onCancelPicture}
          color="negative"
        />
      )
    ];
  }, [onConfirmPicture, src, onCancelPicture]);

  if (diffClass === "changed") {
    return (
      <Fragment>
        <span
          id={uid}
          ref={elementRef}
          onClick={setEditing}
          onBlurCapture={changeValue}
          onKeyDown={keyPress}
          contentEditable={isEditable}
          className={`chunk ${diffClass} ${additionalClasses} added ${isEditable && "current"}`}
          suppressContentEditableWarning={true}
          dangerouslySetInnerHTML={{ __html: renderValue }} // чтобы не вызывалось предупреждение для contentEditable
        />{/* {renderValue}</span>*/}
        {diffCompatitor && (
          <span
            id={"uid-compatitor"}
            className={`chunk ${diffCompatitor.diffClass} ${diffCompatitor.additionalClasses} removed`}
          >{diffCompatitor.renderValue}</span>
        )}
      </Fragment>
    );
  }
  return (
    <>
      <ContextMenu.Trigger
        menuId={data.editable}
        context={data}
        collect={contextMenuCollect}
        className={`chunk ${diffClass} ${isEditable && "current"} ${(isLocked && !isLockedByMe) || (isParentLocked && 
            !isParentLockedByMe) && "locked"}`}
      >
        <span
          id={uid}
          ref={elementRef}
          onClick={setEditing}
          onBlurCapture={changeValue}
          onContextMenu={handleContextMenu}
          onKeyDown={keyPress}
          contentEditable={isEditable}
          onPaste={handlePaste}
          data-tooltip={originTooltip && `${originTooltip} ${datestring}`}
          className={`chunk ${diffClass} ${originClass} ${isEditable && "current"} ${(isLocked && !isLockedByMe) || (isParentLocked && 
            !isParentLockedByMe) && "locked"}`}
          suppressContentEditableWarning={true}
          dangerouslySetInnerHTML={{ __html: renderValue }} // чтобы не вызывалось предупреждение для contentEditable
        />{/* {renderValue}</span>*/}
      </ContextMenu.Trigger>
      <Modal.Window
        name="image"
        icon="image-M"
        show={isInsertInlinePicture}
        title={"Рисунок"}
        buttons={modalButtons}
        onKeyPressEnter={onConfirmPicture}
        onKeyPressEsc={onCancelPicture}
      >
        <div className={"picture-editor"}>
          <div className={"picture-editor-image"}>
            <a
              className={"picture-editor-image-browse"}
              href="#"
              onClick={onBrowse}
            >
              Обзор
            </a>
            <input
              type="file"
              accept="image/*"
              ref={refInput}
              onChange={onSelectImage}
              style={{ display: "none" }}
            />
            {!src && (
              <Components.Icon
                className="picture-editor-image-empty-icon"
                name={"image-M"}
              />
            )}

            {src && <InlinePicture data={{ uid: "temp", value: src }} />}
          </div>
        </div>
      </Modal.Window>
    </>
  );
});

export default Chunk;
