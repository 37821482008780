import React, { useCallback, useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Components } from "@ais3p/ui-framework";
import { observer } from "mobx-react";
import useStores from "~/core/utils/useStores";
import {
  ISSUES_VIEW_KANBAN,
  ISSUES_VIEW_TABLE,
  ISSUES_VIEW_ISSUE
} from "../../constants/issuesViews";
import {
  SIDEPANEL_RELATIONS,
  SIDEPANEL_KINDS_ATTRS
} from "~/core/constants/SidePanels";
import IssueStore from "../../stores/IssueStore";
import InfoToolWindow from "~/core/components/InfoToolWindow/InfoToolWindow.jsx";
import infoToolContent from "~/core/components/InfoToolWindow/infoToolContent.js";
import DropdownButton from "~/core/components/Dropdown/DropdownButton";
import CoordLetterWizard from "../coordLetter/CoordLetterWizard";
import { SIDEPANEL_ISSUES } from "../../../../core/constants/SidePanels";

/**
 * Toolbar для задач
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} params.store хранилище для работы с задачами
 * @param {Boolean} params.canBeEditable признак, можно ли редактировать Задачу
 * @param {LayoutStore} params.layoutStore хранилище для работы с Layout
 * @param {String} params.tabId id вкладки в Layout
 * @param {Object} params.isSubVisible флаг отображения боковой доп панели
 * @param {String} params.issueUid uid Задачи. Если это параметр передан, значит инструмент отрыт для 
 * @param {String} params.projectUid uid Проекта. Если это параметр передан, значит инструмент отрыт для 
 * работы с  Задачами
 */
const IssuesToolbar = observer((props) => {
  const {
    store,
    canBeEditable,
    layoutStore,
    tabId,
    isSubVisible,
    issueUid,
    projectUid,
    onCreateIssue,
    onCancelCreateIssue
  } = props;

  const { kindsStore, accountStore } = useStores();
  const [infoIsVisible, setInfoIsVisible] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [coordLetterTemplate, setCoordLetterTemplate] = useState();

  useEffect(async() => {
    const data = await store.loadCoordLetterTemplates();
    if (Array.isArray(data)) {
      setTemplates(data);
    }
  }, []);

  const project = useMemo(() => {
    return store.getProjectByUid(projectUid);
  }, [store.projects && store.projects.size, projectUid]);

  const readOnly = useMemo(() => {
    if (store.activeIssue) {
      return !store.activeIssue.isEditingMode;
    }
    if (project && project.readOnly) {
      return project.readOnly;
    }
    return store.readOnly;
  }, [
    store.readOnly, 
    store.activeIssue, 
    store.activeIssue && store.activeIssue.isEditingMode, 
    project
  ]);

  const onToggleSubPanelKinds = useCallback(
    () => {
      layoutStore.toggleSubPanel(tabId, SIDEPANEL_KINDS_ATTRS);
    },
    [tabId]
  );

  const onToggleSubPanelRelations = useCallback(
    () => {
      layoutStore.toggleSubPanel(tabId, SIDEPANEL_RELATIONS);
    }, 
    [tabId]
  );

  const onToggleSubPanelIssues = useCallback(
    () => {
      layoutStore.toggleSubPanel(tabId, SIDEPANEL_ISSUES);
    }, 
    [tabId]
  );


  const setReadOnly = useCallback(() => {
    if (store.activeIssue) {
      store.activeIssue.setIsEditingMode(false);
    } else {
      store.updateReadOnly(true);
    }
  }, [store, store.activeIssue]);

  const setEditable = useCallback(() => {
    if (store.activeIssue) {
      store.activeIssue.setIsEditingMode(true);
    } else {
      store.updateReadOnly(false);
    }
  }, [store, store.activeIssue]);

  const onSaveIssueChanges = useCallback(async() => {
    const issue = store.activeIssue;
    if (!issue) {
      return;
    }

    issue && issue.setIsPending(true);
    const { isNew } = issue;
    try {
      const b = await store.saveIssue(issue, issue.jsonTmpValues);
      if (b) {
        issue.setIsEditingMode(false);
        if (isNew) {
          // необходимо, чтобы обновился контекст для вновь созданной задачи
          onCreateIssue && onCreateIssue(issue);
        }
      }
    } catch (e) {
      store.onError(e);
    } finally {
      issue && issue.setIsPending(false);
    }
  }, [store.activeIssue, onCreateIssue]);

  const onCancelIssueChanges = useCallback(() => {
    const issue = store.activeIssue;
    if (!issue) {
      return;
    }
    issue.setIsEditingMode(false);
    issue.isNew && onCancelCreateIssue && onCancelCreateIssue();
  }, [store.activeIssue, onCancelCreateIssue]);


   
  const toggleShowIssueJournal = useCallback(() => {
    const issue = store.activeIssue;
    if (!issue) {
      return;
    }
    issue.toggleShowJournal();
  }, [store.activeIssue]);

  // const createNewIssue = useCallback(
  //   (trackerId) => {
  //     const tracker = store.getTracker(trackerId);
  //     if (!canBeEditable) {
  //       return;
  //     }

  //     if (!tracker) {
  //       store.onError(`Трекер с id=${trackerId} не найден!`);
  //       return;
  //     }

  //     const newIssue = store.createNewIssue(tracker.config);
  //     store.setActiveIssue(newIssue);
  //   },
  //   [canBeEditable]
  // );

  const onStartCreateIssue = useCallback((menuItem) => {
    const { trackerId } = menuItem;
    if (!canBeEditable || !trackerId) {
      return;
    }

    const tracker = store.getTracker(trackerId);
    if (!tracker) {
      store.onError(`Трекер с id=${trackerId} не найден!`);
      return;
    }

    const newIssue = store.createNewIssue(tracker.config);
    store.setActiveIssue(newIssue);
  }, [canBeEditable]);

  const onExportIssue = useCallback(async(menuItem) => {
    const { format, issue } = menuItem;
    const blobFile = await store.exportIssue(issue && issue.uid, format);
    if (!blobFile) {
      return;
    }
    const link = document.createElement("a");
    link.setAttribute("type", "hidden");
    link.setAttribute("download", `${issue.titlePrefix}.${format}`);
    link.setAttribute("target", "_blank");
    link.setAttribute("href", blobFile);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(blobFile);  
  }, []);

  useEffect(() => {
    if (!!issueUid) {
      store.setIssuesViewMode(ISSUES_VIEW_ISSUE);
    }
  }, [issueUid]);

  const toggleShowIssuesModeTable = useCallback(
    () => {
      store.setIssuesViewMode(ISSUES_VIEW_TABLE);
    },
    []
  );

  const toggleShowIssuesModeKanban = useCallback(
    () => {
      store.setIssuesViewMode(ISSUES_VIEW_KANBAN);
    },
    []
  );
  
  const onToggleInfoModal = useCallback(() => {
    setInfoIsVisible(!infoIsVisible);
  }, [infoIsVisible]);

  const onDoWithSelectedIssues = useCallback((menuItem) => {
    switch (menuItem.action) {
      case "createCoordLetter":{
        const { template } = menuItem;
        template && setCoordLetterTemplate(template);
        break;
      }
    }
  }, [store.selectedUidsIssueMap.size]);

  const onCancelCoordLetter = useCallback(() => {
    setCoordLetterTemplate(null);
  }, []);
  
  const onDoneCoordLetter = useCallback(() => {
    setCoordLetterTemplate(null);
  }, []);

  // const onOpenSidePanel = useCallback(({ type }) => {
  //   switch (type) {
  //     case SIDEPANEL_RELATIONS:
  //       onToggleSubPanelRelations();
  //       break;
  //     case SIDEPANEL_KINDS_ATTRS:
  //       onToggleSubPanelKinds();
  //       break;
  //     case SIDEPANEL_ISSUES:
  //       onToggleSubPanelIssues();
  //       break;
  //   }
  // }, [tabId, onToggleSubPanelRelations, onToggleSubPanelKinds]);

  const rightButtons = useMemo(() => {
    let modeButtons = [
      (
        <Components.Button
          key="view"
          icon="review-M"
          tooltip="Просмотр"
          isSelected={readOnly || 
            ((store.activeIssue ? !store.activeIssue.isEditingMode : store.issuesViewMode === ISSUES_VIEW_TABLE))}
          onPress={setReadOnly}
        />
      )
    ];

    if (
      canBeEditable && (store.activeIssue ? !store.activeIssue.readOnly : true) &&
      (store.issuesViewMode === ISSUES_VIEW_KANBAN || store.activeIssue) && !project?.readOnly
    ) {
      modeButtons.unshift(
        <Components.Button
          key="edit"
          icon="editing-M"
          tooltip="Редактировать Задачу"
          text="Редактировать"
          isSelected={!readOnly}
          onPress={setEditable}
        />
      );
    }
    
    const sidePanelsButtons = [
      <Components.Spacer key="right-spacer-2" />,
      <Components.Button
        key={SIDEPANEL_RELATIONS}
        icon="app-relations-M"
        tooltip="Связи"
        isSelected={isSubVisible[SIDEPANEL_RELATIONS]}
        onPress={onToggleSubPanelRelations}
      />,
      <Components.Button
        key={SIDEPANEL_KINDS_ATTRS}
        icon="app-attributes-M"
        tooltip="Виды и атрибуты"
        isSelected={isSubVisible[SIDEPANEL_KINDS_ATTRS]}
        onPress={onToggleSubPanelKinds}
      />,
      <Components.Button
        key={SIDEPANEL_ISSUES}
        icon="app-spzi-M"
        tooltip="Задачи"
        isSelected={isSubVisible[SIDEPANEL_ISSUES]}
        onPress={onToggleSubPanelIssues}
      />
    ];

    // const sidePanelsButtons = [
    //   <DropdownButton 
    //     key="side-palens"
    //     // icon="import-M"
    //     text="Инструменты"
    //     tooltip="Дополнительные инструменты"
    //     items={[{
    //       icon:  "app-relations-M",
    //       title: "Связи",
    //       type:  SIDEPANEL_RELATIONS
    //     }, {
    //       icon:  "app-attributes-M",
    //       title: "Виды и атрибуты",
    //       type:  SIDEPANEL_KINDS_ATTRS
    //     }, {
    //       icon:  "app-spzi-M",
    //       title: "Задачи",
    //       type:  SIDEPANEL_ISSUES
    //     }]}
    //     onClickItem={onOpenSidePanel}
    //   />
    // ];

    modeButtons = modeButtons.concat(sidePanelsButtons);

    if (store.activeIssue) {
    // Активна вкладка с задачей
      const issue = store.activeIssue;
      modeButtons.push(
        <Components.Button
          key="journal"
          icon="log-M"
          tooltip="Журнал изменений"
          isSelected={issue && issue.isShownJournal}
          onPress={toggleShowIssueJournal}
        />
      );
    }

    modeButtons.push(<Components.Spacer key="right-spacer-3" />);
    modeButtons.push(
      <Components.Button
        key={"info-M"} icon="info-M" tooltip={"Справка"}
        onPress={onToggleInfoModal}
        isSelected={infoIsVisible}
      />
    );

    return modeButtons;
  }, [
    readOnly,
    canBeEditable,
    store.issuesViewMode,
    isSubVisible,
    store.activeIssue,
    store.activeIssue && store.activeIssue.isShownJournal,
    project?.readOnly
  ]);

  const leftButtons = useMemo(() => {
    if (store.activeIssue) {
      // Активна вкладка с задачей
      const issue = store.activeIssue;
      let buttons = [];
      if (issue.isEditingMode && !issue.readOnly) {
        buttons = [
          <Components.Button
            key="saveChanges"
            icon="save-M"
            tooltip="Сохранить изменения"
            color={issue.hasChanges ? "positive" : ""}
            isDisabled={!canBeEditable || !issue.isTmpValuesValid || !issue.hasChanges}
            isLoading={issue.isPending}
            onPress={onSaveIssueChanges}
          />,
          <Components.Button
            key="cancelChanges"
            icon="cancel-M"
            tooltip="Отменить изменения"
            onPress={onCancelIssueChanges}
          />
        ];
      }
      if (!issue.isNew) {
        if (buttons.length > 0) {
          buttons.push(<Components.Spacer key={"spacer-export"} />);
        }
        buttons.push(
          <DropdownButton 
            key="export-issue"
            icon="import-M"
            text="Экспорт"
            tooltip="Экспортировать задачу"
            items={[{
              icon:   "file-pdf-M",
              title:  "PDF",
              format: "pdf",
              issue
            }, {
              icon:   "file-docx-M",
              title:  "DOCX",
              format: "docx",
              issue
            }]}
            onClickItem={onExportIssue}
          />
        );
      }
      
      return buttons.length > 0 ? buttons : null;
    }

    if (!projectUid || !project) {
      return [];
    }
    
    // Режим просмотра списка задач
    const kindMember = kindsStore.getKindMemberSync(projectUid);
    // Формируем список кнопок для создания задач
    // const createIssueButtons = store.trackerList
    const createIssueButtons = project.trackers
      .filter((tracker) => {
        const kind = kindsStore.getKind(tracker.uid);
        return kindMember && kindMember.allowedTasks.has(kind?.uid);
      })
      .map((tracker) => {
        const kind = kindsStore.getKind(tracker.uid);
        return (
          {
            icon:      kind && accountStore.getIcon(kind.uid),
            trackerId: tracker.id,
            title:     tracker.name,
            tooltip:   `Создать "${tracker.name}"`,
            action:    "createIssue"
          }
          // <Components.Button
          //   key={tracker.id}
          //   icon={kind && accountStore.getIcon(kind.uid)}
          //   tooltip={`Создать "${tracker.name}"`}
          //   isDisabled={!canBeEditable}
          //   // eslint-disable-next-line react/jsx-no-bind
          //   onPress={() => {
          //     return createNewIssue(tracker.id);
          //   }}
          // />
        );
      });

    const doWithSelectedIssues = [];
    if (store.selectedUidsIssueMap.size > 0 && templates.length > 0) {
      if (createIssueButtons.length > 0) {
        doWithSelectedIssues.push(<Components.Spacer key={"spacer-selected"} />);
      }
      doWithSelectedIssues.push(
        <DropdownButton 
          key="selected-issues"
          text="Выбранные задачи"
          tooltip="Возможные операции с выбранными задачами"
          items={[{
            title: "Создать КП",
            icon:  "chapter-M",
            items: templates.map((template) => {
              return {
                title:  template.name,
                template,
                action: "createCoordLetter"
              };
            })
          }]}
          onClickItem={onDoWithSelectedIssues}
        />
      );
    }

    return [].concat(
      [
        <Components.Button
          key={ISSUES_VIEW_TABLE}
          icon="table-M"
          tooltip="Отобразить список задач в Табличном представлении"
          isSelected={store.issuesViewMode === ISSUES_VIEW_TABLE}
          onPress={toggleShowIssuesModeTable}
        />,
        <Components.Button
          key={ISSUES_VIEW_KANBAN}
          icon="module-M"
          tooltip="Отобразить список задач в Канбан представлении"
          isSelected={store.issuesViewMode === ISSUES_VIEW_KANBAN}
          onPress={toggleShowIssuesModeKanban}
        />
      ],
      !project?.readOnly && createIssueButtons.length > 0 ? 
        [
          <Components.Spacer key={"spacer-modes"} />,
          <DropdownButton 
            key="create-issue"
            text="Создать"
            tooltip="Создать новую задачу"
            items={createIssueButtons}
            onClickItem={onStartCreateIssue}
          />
        ] : [],
      doWithSelectedIssues
    );
  }, [
    // additionalButtons,
    readOnly,
    store.trackerList,
    store.activeIssue,
    store.activeIssue && store.activeIssue.isPending,
    canBeEditable,
    isSubVisible,
    store.issuesViewMode,
    projectUid,
    project,
  
    store.activeIssue && store.activeIssue.hasChanges,
    store.activeIssue && store.activeIssue.isTmpValuesValid,
    store.selectedUidsIssueMap.size,
    templates && templates.length
  ]);

  return (
    <>
      <Components.ToolBar right={rightButtons} >{leftButtons}</Components.ToolBar>
      {infoIsVisible && (
        <InfoToolWindow 
          content={infoToolContent.issues}
          infoIsVisible={infoIsVisible}
          toggleInfoModal={onToggleInfoModal}
        />
      )}
      {coordLetterTemplate && 
        <CoordLetterWizard 
          store={store} 
          templateUid={coordLetterTemplate.uid}
          onCancel={onCancelCoordLetter}
          onDone={onDoneCoordLetter}
        />
      }
    </>
  );
});

IssuesToolbar.propTypes = {
  store:         PropTypes.instanceOf(IssueStore),
  canBeEditable: PropTypes.bool,
  layoutStore:   PropTypes.object,
  tabId:         PropTypes.string,
  isSubVisible:  PropTypes.object,
  projectUid:    PropTypes.string,
  issueUid:      PropTypes.string

};

export default IssuesToolbar;
