import { action, computed, observable } from "mobx";
import { v4 as uuid } from "uuid";

import Text from "../models/Text";
import Rubric from "../models/Rubric";
import Indent from "../models/Indent";
import List from "../models/List";
import ListItem from "../models/ListItem";
import Varchar from "../models/Varchar";
import Table from "../models/Table";
import TableCell from "../models/TableCell";
import TableRow from "../models/TableRow";
import Code from "../models/Code";
import CodeLine from "../models/CodeLine";
import TextApi from "../api/TextApi";
import InlinePicture from "../models/InlinePicture";
import Picture from "../models/Picture";
import Caption from "../models/Caption";
import InlineFormula from "../models/InlineFormula";
import Formula from "../models/Formula";
import CodeLineChunk from "../models/CodeLineChunk";
import { DOMAIN_LIBRARY, DOMAIN_TEXT } from "../../../core/constants/Domains";
import { MODULE_NAME_CFG } from "../constants/config";

class DataStore {
  @observable
  rootStore = null; // RootStore
  @observable
  objectStore = null; // ObjectStore
  @observable
  layoutStore = null; // ObjectStore

  @observable
  editable = null;
  @observable
  parentStore = null; // ObjectStore
  @observable
  diffStore = null; // ObjectStore

  @observable
  libUid = null;

  @observable
  libraryRepresentation = null;

  @observable
  isStore = true;
  @observable
  pending = false;
  @observable
  diffPending = false;
  @observable
  versionPending = false;
  @observable
  items = new Map();
  @observable
  rootItem = null;
  @observable
  editingChunkId = null;
  @observable
  delta = null;
  @observable
  editingChunkOffset = null;
  @observable
  focusUid = null;
  @observable
  version = null;
  @observable
  windowHeight = 0;
  @observable
  scrollTop = 0;

  @observable
  startIndex = 0;
  @observable
  endIndex = 0;

  @observable
  prerenderCount = 100;
  @observable
  maxWindowHeight = 101;
  @observable
  viewWidth = 0;

  @observable
  lastItemIndexToEnterView = null;
  @observable
  scrollItemId = null;
  @observable
  scrollDelta = 0;

  @observable
  styleVersion = "latest";

  @observable
  hoverTableUid = null;

  @observable
  plusVisible = false;

  static textItemFabric(data, version, textStore) {
    const { uid: id, class: className, validations } = data;
    let item = null;
    switch (className) {
      case "text.form.Text":
        item = new Text(id, version, textStore, validations);
        break;
      case "text.container.Rubric":
        item = new Rubric(id, version, textStore, validations);
        break;
      case "text.element.Indent":
        item = new Indent(id, version, textStore, validations);
        break;
      case "text.element.Caption":
        item = new Caption(id, version, textStore, validations);
        break;
      case "text.chunk.Varchar":
        item = new Varchar(id, version, textStore, validations);
        break;
      case "text.chunk.InlineCode":
        item = new CodeLineChunk(id, version, textStore, validations);
        break;
      case "text.element.Picture":
        item = new Picture(id, version, textStore, validations);
        break;
      case "text.chunk.InlinePicture":
        item = new InlinePicture(id, version, textStore, validations);
        break;
      case "text.element.Enumeration":
        item = new List(id, version, textStore, validations);
        break;
      case "text.container.EnumerationItem":
        item = new ListItem(id, version, textStore, validations);
        break;
      case "text.chunk.InlineFormula":
        item = new InlineFormula(id, version, textStore, validations);
        break;
      case "text.element.Formula":
        item = new Formula(id, version, textStore, validations);
        break;
      case "text.element.code":
      case "text.element.CodeBlock":
        item = new Code(id, version, textStore, validations);
        break;
      case "text.element.code.line":
      case "text.element.CodeLine":
        item = new CodeLine(id, version, textStore, validations);
        break;
      case "text.element.Table":
        item = new Table(id, version, textStore, validations);
        break;
      case "text.container.TableRow":
        item = new TableRow(id, version, textStore, validations);
        break;
      case "text.container.TableCell":
        item = new TableCell(id, version, textStore, validations);
        break;
      default:
        console.warn("Unknown class", className, data);
        break;
    }

    return item;
  }

  constructor(rootStore, layoutStore, parentStore = null) {
    this.rootStore = rootStore;
    this.layoutStore = layoutStore;
    this.objectStore = this.rootStore.objectStore;
    this.parentStore = parentStore;
    this.api = new TextApi(rootStore);
  }
  @action
  beforeUnload() {
    this.items.forEach((item) => {
      if ((item.className === "text.element.Indent" || item.isEdiatbleChunk) && item.isLocked) {
        item.unlock();
      }
    });
  }

  @action
  async init(id, libUid, version, selectedUid) {
    this.editable = id;
    this.windowHeight = 0;
    this.delta = null;
    this.scrollDelta = 0;
    this.startIndex = 0;
    this.scrollTops = 0;
    this.endIndex = 0;
    this.focusUid = null;
    this.items.clear();
    this.focusUid = null;
    this.editingChunkId = null;
    this.version = version;
    this.libUid = libUid;
    this.diffStore = null;
    if (!libUid) {
      const result = await this.objectStore.librarySearch({ editable: id });
      if (result && result.length) {
        this.libUid = result[0].uid;
      }
    }
    this.getLibraryRepresentation(this.libUid);

    await this.loadText(id, version, selectedUid);
  }

  @action
  async initDiff(version) {
    this.setDiffPending(true);
    this.diffStore = null;
    this.items.forEach((item) => {
      item.setDiffType(null);
    });
    if (version !== null) {
      this.diffStore = new DataStore(this.rootStore, this.layoutStore, this);
      await this.diffStore.init(this.editable, this.libUid, version);
      const diffData = await this.api.getDiff(this.editable, this.version, this.diffStore.version);
      this.processDiffData(diffData);
    }
    // TODO: load diff and serealize result to objectStore
    this.setDiffPending(false);
  }

  @action
  processDiffData(data) {
    if (!data || !data.changeList || !data.changeList.length) {
      return;
    }
    data.changeList.forEach((diff) => {
      const uid = (diff.subject || diff.object).replaceAll("-", "");
      const item = this.getItemById(uid);
      const comparedItem = this.diffStore.getItemById(uid);
      item && item.setDiffType(diff.type);
      comparedItem && comparedItem.setDiffType(diff.type);
    });
  }

  @action 
  async getDiffData(version) {
    this.setVersionPending(true);
    const data = await this.api.getDiff(this.editable, this.version, version);
    this.setVersionPending(false);
    return data;
  }

  @action
  setDiffPending(pending = false) {
    this.diffPending = pending;
  }

  getUid() {
    return uuid().replace(/-/gi, "");
  }

  @action
  setRoot(item) {
    this.rootItem = item;
  }

  @action
  async getLibraryRepresentation(uid) {
    const nodeItem = await this.objectStore.fetchRepresentation(
      uid, 
      DOMAIN_LIBRARY, 
      0, 
      {}, 
      { force: true }
    );
    if (nodeItem.class === "library.TextVersion") {
      this.libraryRepresentation = nodeItem.parent;
    } else {
      this.libraryRepresentation = nodeItem;
    }
  }

  @action
  setHoverTableUid(uid = null) {
    const prevTable = this.getItemById(this.hoverTableUid);
    if (prevTable) {
      prevTable.dropSelection();
    }
    this.hoverTableUid = uid;
  }

  @action
  async saveVersion(uid) {
    this.setVersionPending(true);
    let result = null;
    try {
      if (this.styleVersion === "latest") {
        result = await this.api.getStyle({ name: DOMAIN_TEXT });
      }
    } catch (error) {
      console.warn(error);
    }
    let versionData;
    try {
      versionData = await this.api.saveVersion(uid);
    } catch {
      this.setVersionPending(false);
      return false;
    }
    const item = await this.objectStore.processLibraryItem(versionData, DOMAIN_LIBRARY, {});
    this.libraryRepresentation && this.libraryRepresentation.addChild(item.uid);
    item.setParentUid(this.libraryRepresentation.uid);
    await this.getLibraryRepresentation(this.libUid);
    if (result) {
      try {
        const styleVersion = Array.from(Object.keys(result.versions))[0];
        await this.api.createStyleLink({
          styleUid:    result.uid,
          styleVersion,
          textUid:     versionData.forms[0].uid,
          textVersion: versionData.number
        });
      } catch (error) {
        console.warn(error);
      }
    }
    this.setVersionPending(false);
  }

  @action
  setVersionPending(pending = false) {
    this.versionPending = pending;
  }

  @action
  determineNewParentData(targetToDropAfter) {
    let ancor = targetToDropAfter;
    let ancorUid = targetToDropAfter.uid;
    let parent = ancor.parent;
  
    if (targetToDropAfter.ancorId) {
      ancor = this.getItemById(targetToDropAfter.ancorId);
      ancorUid = targetToDropAfter.ancorId;
      parent = ancor.parent;
    }
    if (targetToDropAfter.isSibling) {
      parent = ancor.parent;
    }
    if (targetToDropAfter.isParent) {
      parent = ancor;
      ancorUid = null;
    }
  
    return { parent, ancorUid };
  }


  @action
  async move(element, targetToDropAfter) {    
    const { parent, ancorUid } = this.determineNewParentData(targetToDropAfter);
    const fromUid = element.parent.uid;
    element.parent.deleteItemId(element.uid);
    element.setParent(parent);
    parent.insertAfter(element, ancorUid);
    await this.api.moveElement(element.uid, fromUid, parent.uid, element.position);
  }

  @action
  updateItems(itemsArray) {
    if (itemsArray && itemsArray.length) {
      itemsArray.forEach((itemData) => {
        let item = this.getItemById(itemData.uid);
        if (!item) {
          item = this.itemFabric(itemData, this.version);
          this.items.set(item.uid, item);
        }
      });
      this.initAllItems(itemsArray, true);
    }
  }

  @action
  setFocusUid(uid) {
    this.focusUid = uid;
  }

  @action
  async uploadFile(file) {
    const data = await this.api.uploadFile(file);
    return data && data[0] && data[0].id;
  }

  processStyleEntry(prefix, data, styleVersion, parentKey = "") {
    const styleKeys = {
      rubric: [
        " .indent-body",
        " .index-number"
      ]
    };
    let text = "";
    Object.keys(data).forEach((key) => {
      if (key === "media" && !!data.media && !!data.media.screen) {
        if (!!styleKeys[parentKey] && styleKeys[parentKey].length) {
          styleKeys[parentKey].forEach((suffix) => {
            const selector = `${prefix}${suffix}`;
            text += `${selector} {${Object.entries(data.media.screen).map(([k, v]) => {
              return `${k}:${v}`;
            })
              .join(";")}}\n`;  
          });
        } else {
          text += `${prefix} {${Object.entries(data.media.screen).map(([k, v]) => {
            return `${k}:${v}`;
          })
            .join(";")}}\n`;
        }
      } else if (!!data[key]) {
        const newPrefix = `${prefix}${key[0] === "&" ? "" : " "}.${key.replace("&", "")}${key[0] === "&" ? "" : styleVersion}`;
        text += this.processStyleEntry(newPrefix, data[key], styleVersion, key[0] === "&" ? parentKey : key);
      }
    });
    return text;
  }

  @action
  setStyleVersion(version = "latest") {
    this.styleVersion = version;
  }

  @action
  setLock(uid) {
    return this.api.setLock({ uid });
  }

  @action
  unsetLock(uid) {
    return this.api.unsetLock({ uid });
  }

  @action
  async loadText(id, version) {
    this.setPending(true);
    this.setStyleVersion();
    this.rootItem = null;
    this.items.clear();
    try {
      const styleVersionResult = await this.api.getStyleLink({ uid: id, version });
      if (styleVersionResult) {
        this.setStyleVersion(styleVersionResult.styleVersion);
      } else {
        if (version !== 0) {
          this.setStyleVersion("unknown-style");
        }
      }
    } catch (error) {
      if (version !== 0) {
        this.setStyleVersion("unknown-style");
      }
      console.error(error);
    }
    const domain = DOMAIN_TEXT;
    let result = null;
    try {
      if (this.styleVersion === "latest") {
        result = await this.api.getStyle({ name: domain });
      } else {
        result = await this.api.getStyleVersion({ name: domain, version: this.styleVersion });
      }
    } catch (error) {
      console.error(error);
    }
    if (result && result.versions) {
      const ver = Array.from(Object.keys(result.versions))[0];
      const css = this.processStyleEntry(`.${domain}`, result.versions[ver], `.style-version-${this.styleVersion}`);
      const head = document.head || document.getElementsByTagName("head")[0];
      const style = document.createElement("style");
      head.appendChild(style);
      style.type = "text/css";
      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    }
    await this.objectStore.fetchRepresentation(this.libUid, DOMAIN_LIBRARY);
    const data = await this.api.text(id, version); // add true to get local data
    if (data && data.Text) {
      const text = data.Text;
      let members = [];
      let links = [];
      const objects = text.objects.map((object) => {
        if (object.links && object.links.length) {
          links = links.concat(object.links);
        }
        if (object.members && object.members.length) {
          members = members.concat(object.members.map((member) => {
            if (member.kind && member.kind.uid) {
              const codeValues = {};
              const values = {};
              const dataObj = { 
                ...member, 
                kindUid:  member.kind.uid, 
                objectId: object.uid, 
                version 
              };
              if (member.codeValues && member.codeValues.length) {
                member.codeValues.forEach((codeValue) => {
                  if (codeValue.code && codeValue.code.uid) {
                    codeValues[codeValue.code.uid] = codeValue.value;
                  }
                });
              }
              if (member.values && member.values.length) {
                member.values.forEach((value) => {
                  if (value.attribute && value.attribute.uid) {
                    values[value.attribute.uid] = value.value;
                  }
                });
              }
              dataObj.codeValues = codeValues;
              dataObj.values = values;
              return (dataObj);
            } else {
              return null;
            }
          }));
        }
        return object.representation;
      });

      await this.processData(objects, version);
      await this.rootStore.kindsStore.processMembers(members, version);
      await this.rootStore.relationStore.processRelations(links, false, false);
      this.initAllItems(objects);
      if (!version) {
        const locks = await this.api.getLocks({ uid: id });
        if (locks && locks.length) {
          locks.forEach((lock) => {
            const element = this.getItemById(lock.object);
            if (!!element) {
              element.setLockData(lock);
            }
          });
        }
      }  
    }
    this.setPending(false);
  }

  @action
  initAllItems(data, notText = false) {
    data.forEach((itemData, i) => {
      const item = this.items.get(itemData.uid);
      if (item) {
        if (i === 0 && !notText) {
          item.setParent(this);
          this.setRoot(item);
        }
        item.init(itemData);
      }
    });
  }

  @action
  initItem(itemData) {
    const item = this.items.get(itemData.uid);
    item.init(itemData);
  }

  @action
  addItem(item) {
    this.items.set(item.uid, item);
  }
  
  @action
  async processData(data, version) {
    const array = [];
    let validations = {};

    if (version > 0 && data.length > 0 && !!data[0].uid) {
      // get validation for text
      validations = await this.objectStore.getApprovals(data[0].uid, version);
    }

    data.forEach((itemData) => {
      const item = this.itemFabric({ ...itemData, validations: validations[`${itemData.uid}-${version}`] || {} }, version);
      if (item) {
        array.push(item.uid);
        this.addItem(item);
      }
    });
    return array;
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  setPlusVisible(isVisible = false) {
    this.plusVisible = isVisible;
  }

  @action
  async insertColumn(data) {
    const result = await this.api.insertColumn(data);
    this.updateItems(result);
    return result;
  }

  @action
  async insertRow(data) {
    const result = await this.api.insertRow(data);
    this.updateItems(result);
    return result;
  }

  @action
  async mergeCells(uid, data) {
    const result = await this.api.mergeCells(uid, data);
    this.updateItems(result);
    return result;
  }

  @action
  async splitCell(uid, direction) {
    const result = await this.api.splitCell(uid, direction);
    this.updateItems(result);
    return result;
  }

  @action
  async deleteRow(rowNumber, tableUid) {
    const result = await this.api.deleteRow(rowNumber, tableUid);
    this.updateItems(result);
    return result;
  }

  @action
  async deleteColumn(columnNumber, tableUid) {
    const result = await this.api.deleteColumn(columnNumber, tableUid);
    this.updateItems(result);
    return result;
  }
  // rubrics[], elements[], chunks[], header<indx>

  @action
  itemFabric(data, version) {
    const item = DataStore.textItemFabric(data, version, this);
    if (item) {
      this.objectStore.addVersion(item);
    }
    return item;
  }

  @action
  createItem(data, parent, kindData = null) {
    const item = this.itemFabric(data);
    if (item) {
      this.items.set(item.uid, item);
      item.init(data, parent);
    }
    if (kindData && kindData.kind !== "type-only") {
      item.setIsUsedInWizard(true);
      const kind = this.rootStore.kindsStore.getKind(kindData.kind);
      const kindIcon = !!kind && this.rootStore.accountStore.getIcon(kind.uid);
      if (kind) {
        const itemToOpen = {
          icon:      kindIcon || "wizard-M",
          id:        item.uid,
          label:     kind.name,
          parent:    parent.uid,
          onSuccess: async(data) => {
            await this.saveAfterWizard(data); // arrow functions here to preserve "this"
          },
          onCancel: async(id) => {
            await this.cleanAfterCancel(id); // arrow functions here to preserve "this"
          },
          config: {
            instantConfirm: false,
            nameTitle:      "Название",
            withName:       true,
            views:          [
              {
                type:   "kind",
                kindId: kind.id
              }
            ]
          }
          
        };
    
        this.rootStore.uiStore.setWizard(itemToOpen);
      }
    }
    return item;
  }

  @action
  async cleanAfterCancel(id) {
    const item = this.getItemById(id);
    if (item) {
      await item.selfDestruct();
    }
  }

  @action
  async saveAfterWizard(data) {
    const item = this.getItemById(data.id);
    if (item) {
      await item.createCaption(data.name, true);
      await item.saveKinds();
      item.setIsUsedInWizard(false);
    }
  }

  @action
  getItemById(id) {
    return this.items.get(id) || null;
  }

  @action
  deleteItemById(id, noPersist = false) {
    const item = this.items.get(id);
    try {
      if (!noPersist) {
        item.persistDelete();
      }
    } catch (e) {
      console.log("Already deleted, no need to persist");
    }
    this.items.delete(id);
  }

  @action
  setEditingChunkId(uid, offset = null) {
    this.editingChunkId = uid;
    this.setOffset(offset);
  }

  @action
  setDelta(delta) {
    this.delta = delta;
  }

  @action
  setOffset(offset) {
    this.editingChunkOffset = offset;
  }

  @action
  unsetEditingChunkId() {
    this.delta = null;
    this.editingChunkId = null;
    this.editingChunkOffset = null;
  }

  @action
  setPrevChild(id) {
    console.warn("Can't switch root item", id);
  }
  @action
  setNextChild(id) {
    console.warn("Can't switch root item", id);
  }

  @action
  setWindowHeight(height = 0) {
    const newHeight = Math.floor(height);
    if (newHeight !== this.windowHeight) {
      this.windowHeight = Math.floor(height);
    }
  }

  @action
  processResize(width = 0) {
    if (width !== this.viewWidth && this.lastItemIndexToEnterView !== null) {
      let item = this.flatItemsArray[this.lastItemIndexToEnterView];
      if (!item) {
        item = this.flatItemsArray[0];
      }
      this.scrollToItemById(item.uid);
    } else {
      this.scrollTop -= 1;
    }
  }

  @action
  scrollToItemById(id) {
    this.scrollItemId = null;
    if (!id) {
      return null;
    }
    let itemIndex = this.flatItemsIndexMap[id];
    if (!itemIndex) {
      const item = this.getItemById(id);
      itemIndex = this.flatItemsIndexMap[item.flatParentId];
    }
    this.setWindowByIndex(itemIndex);
    this.scrollItemId = id;
  }

  @action
  setScrollDelta(delta = 0) {
    this.scrollDelta = delta;
  }

  @action
  setScrollTop(top = 0, index) {
    if (index !== undefined) {
      this.setWindowByIndex(index);
    }
    this.scrollTop = top;
  }

  @action
  setWindowByIndex(index = 0) {
    this.startIndex = index;
    this.endIndex = index;
  }

  @action
  setBoundingIndex(index = 0) {
    if (this.scrollDelta > 0) {
      this.lastItemIndexToEnterView = index;
      this.setBound("start", index);
      this.setBound("end", index + this.prerenderCount);
    }
    if (this.scrollDelta <= 0) {
      this.setBound("start", index - this.prerenderCount);
      this.setBound("end", index);
    }
  }

  @action
  unsetBoundingIndex(index = 0) {
    if (this.scrollDelta < 0) {
      this.lastItemIndexToEnterView = index;
      this.setBound("start", index);
    }
    if (this.scrollDelta > 0) {
      this.setBound("end", index);
    }
  }

  @action
  setBoundingIndexFromSpacer(position) {
    if (position === "top") {
      this.setBound("start", this.startIndex - this.prerenderCount);
      this.setBound("end", this.startIndex + this.prerenderCount);
    }
    if (position === "bottom") {
      this.setBound("end", this.endIndex + this.prerenderCount);
    }
  }

  @action
  setBound(position = "start", index, force = false) {
    if (position === "start") {
      const newIndex = Math.max(0, index);
      if (
        newIndex > this.endIndex - this.maxWindowHeight &&
        newIndex <= this.endIndex
      ) {
        this.startIndex = newIndex;
      } else if (force) {
        this.setWindowByIndex(index);
      }
    } else if (position === "end") {
      const newIndex = Math.min(
        this.flatItemsArray.length,
        index,
        this.startIndex + this.maxWindowHeight
      );
      if (
        newIndex < this.startIndex + this.maxWindowHeight &&
        newIndex >= this.startIndex
      ) {
        this.endIndex = newIndex;
      } else if (force) {
        this.setWindowByIndex(index);
      }
    }
  }

  /**
   * Экспортировать версию
   * 
   * @param {String} uid
   *  @param {object} data
   */

  @action
  async exportToForm(uid, data) {
    this.setPending(true);
    const node = await this.objectStore.getLibraryNodeByEditableUid(uid);
    try {
      const res = await this.api.export(data.format, node.uid, data.version);
      return res;
    } catch (ex) {
      this.onError(ex);
    } finally {
      this.setPending(false);
    }
    return null;
  }

  /**
   * Обработать ошибку
   * 
   * @param {String} msg 
   */
  onError(msg) {
    this.rootStore.onError(msg);
  }

  @action
  async mergeElements(target, source, position = 0) {
    try {
      await this.api.moveElements(target.uid, (source && source.idsArray) || [], position);
    } catch (ex) {
      this.onError(ex);
    } finally {
      if (!!source) {
        source.idsArray.forEach((id) => {
          target.addItemById(id);
        });
        target.parent.delete(source.uid);
      }
    }
  }

  @action
  async moveChunks(target, subTree, position = 0) {
    try {
      await this.api.moveElements(target.uid, subTree, position);
    } catch (ex) {
      this.onError(ex);
    } finally {
      subTree.forEach((id) => {
        target.addItemById(id);
      });
    }
  }

  @computed
  get toolConfig() {
    const toolConfig = this.rootStore.configStore.getToolConfig(this.toolId);
    return toolConfig || { typeCreate: [], kindCreate: [] };
  }

  @computed
  get typeCreate() {
    return this.toolConfig.typeCreate;
  }

  @computed
  get kindToTypeMapping() {
    const mappingObject = {}; // kindName: type
    this.toolConfig.kindCreate.forEach((item) => {
      mappingObject[item.kind] = item.as;
    });
    return mappingObject;
  }

  @computed
  get typeToKindMapping() {
    const mappingObject = {}; // kindName: type
    this.toolConfig.kindCreate.forEach((item) => {
      const icon = this.rootStore.accountStore.getIcon(item.kind) || "token-M";
      const kind = this.rootStore.kindsStore.getKind(item.kind);
      if (mappingObject[item.as]) {
        mappingObject[item.as].push({ kind: item.kind, title: kind && kind.name, icon });
      } else {
        mappingObject[item.as] = [{ kind: item.kind, title: kind && kind.name, icon }];
      }
    });
    return mappingObject;
  }

  @computed
  get focusItem() {
    return this.items.get(this.focusUid);
  }

  @computed
  get availableDiffVersions() {
    const versions = [];
    if (
      !this.libraryRepresentation || 
      !this.libraryRepresentation.childItems || 
      !this.libraryRepresentation.childItems.length || 
      this.version === 1
    ) {
      return versions;
    }
    this.libraryRepresentation.childItems.forEach((child) => {
      if ((child && child.number < this.version) || this.version === 0) {
        versions.push(child);
      }
    });
    return versions;
  }

  @computed
  get root() {
    return this.isPending ? null : this.rootItem;
  }

  @computed
  get rootId() {
    return this.rootItem && this.rootItem.uid;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get canSaveVersion() {
    return this.libraryRepresentation 
    && this.libraryRepresentation.permissions 
    && this.libraryRepresentation.permissions.get("write");
  }

  @computed
  get isDiffPending() {
    return this.diffStore && this.diffPending && this.diffStore.isPending;
  }

  @computed
  get isDiffStore() {
    return !!this.parentStore;
  }

  @computed
  get isVersion() {
    return !!this.version || this.isDiffStore;
  }

  @computed
  get isReady() {
    return !!this.items.size;
  }

  @computed
  get isVersionPending() {
    return this.versionPending;
  }

  @computed
  get flatItemsArray() {
    return !this.rootItem ? null : this.rootItem.flatItemsArray;
  }

  @computed
  get flatItemsIndexMap() {
    const obj = {};
    this.flatItemsArray.forEach((item, index) => {
      obj[item.uid] = index;
    });
    return obj;
  }

  // virt list section
  @computed
  get isLongList() {
    return this.flatItemsArray.length > 1000;
  }

  @computed
  get listFullHeight() {
    if (this.flatItemsArray.length === 0) {
      return 0;
    }
    return this.flatItemsArray[this.flatItemsArray.length - 1].bottom || 0;
  }

  @computed
  get windowTopIndex() {
    return Math.max(0, this.startIndex - this.prerenderCount);
  }

  @computed
  get isPlusVisible() {
    return this.plusVisible && !!this.focusItem && this.canAddItem;
  }

  @computed
  get canAddItem() {
    return true;
  }

  @computed
  get windowBottomIndex() {
    return Math.min(
      this.flatItemsArray.length,
      this.endIndex + this.prerenderCount
    );
  }

  @computed
  get topSpacerHeight() {
    if (!this.windowTopIndex) {
      return 0;
    }
    return this.flatItemsArray[this.windowTopIndex].top;
  }

  @computed
  get bottomSpacerHeight() {
    if (this.windowBottomIndex === this.flatItemsArray.length) {
      return 0;
    }
    return (
      this.listFullHeight - this.flatItemsArray[this.windowBottomIndex].bottom
    );
  }

  // end virt list section

  @computed
  get flatTreeItemsArray() {
    const data = [];
    this.flatItemsArray &&
      this.flatItemsArray.forEach((item, index) => {
        item.tree && data.push({ ...item.tree, index });
      });

    return data;
  }

  /**
   * Получить конфигурацию инструмента;
   *
   * @return {Object}
   */
  @computed
  get config() {
    return this.rootStore.uiStore.getModuleConfig(MODULE_NAME_CFG);
  }

  /**
   * Получить параметр конфигурации инструмента
   *
   * @param {String} name название параметра
   * @return {Object}
   */
  getItemConfig(name) {
    return (this.config && this.config[name]) || {};
  }

  /**
   * Задать параметр конфигурации инструмента
   *
   * @param {String} name название параметра
   * @param {Object} params набор параметров
   */
  setItemConfig(name, params) {
    this.rootStore.uiStore.setModuleConfig(MODULE_NAME_CFG, {
      ...this.config,
      [name]: params
    });
  }
}

export default DataStore;
